@use '_malou-typography' as *;
@use '_malou_variables' as *;
@use '_malou_scrollbar' as *;
@use '_malou_functions' as *;

.malou-tab {
    @extend .malou-text-12--medium;
    cursor: pointer;
}

.malou-tab:hover {
    font-weight: bold;
}

/* SOLUTION avoid other elements moving */
/* https://stackoverflow.com/a/20249560/15323388 */
/* The pseudo element has the same content and hover style, so it pre-sets the width of the element and visibility: hidden hides the pseudo element from actual view. */
.malou-tab::before {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
}

.mdc-tab--active .mdc-tab__text-label {
    color: $malou-color-text-1 !important;
}

.mdc-tab__text-label {
    color: $malou-color-text-2 !important;
}

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs > .mat-mdc-tab-header .mat-mdc-tab,
.mat-mdc-tab-header.mat-mdc-tab-nav-bar-stretch-tabs .mat-mdc-tab-link {
    flex-grow: 0 !important;
}

.mdc-tab.mat-mdc-tab {
    margin-right: toRem(26px);
    opacity: 1 !important;
    @extend .malou-text-14--regular;
    color: $malou-color-text-2 !important;

    &.mat-mdc-tab-disabled {
        opacity: 0.4 !important;
    }
}

.mdc-tab--active .mdc-tab__content {
    @extend .malou-text-14--semibold;
}

.mdc-tab__ripple {
    display: none;
}

.malou-tab-group {
    .mat-mdc-tab-label-container {
        height: toRem(65px);
        padding-left: toRem(34px);
        padding-right: toRem(34px);
        background-color: #{$malou-color-background-white};
    }

    .mat-mdc-tab-header {
        border-bottom: 1px solid #{$malou-color-background-dark} !important;
    }

    .mdc-tab.mat-mdc-tab {
        margin-right: toRem(10px);
    }

    .mdc-tab-indicator--active .mdc-tab-indicator__content {
        opacity: 0 !important; // Hide the underline
    }

    .mdc-tab__content {
        @extend .malou-text-14;
        height: 30px !important;
        margin-top: toRem(18px);
        padding: 0 toRem(10px);
    }

    .mdc-tab--active .mdc-tab__content {
        border-radius: toRem(5px);
        background-color: #{$malou-color-background-dark};
    }
}
