// This file is imported in angular.json

// https://material.angular.io/guide/theming
@use 'malou/_malou_variables' as *;
@use 'malou/_malou_functions' as *;
@use 'malou/_malou_boxshadows' as *;
@use '@angular/material' as mat;
@include mat.core();

// https://material.io/design/color/#tools-for-picking-colors
$malou-theme-primary: mat.m2-define-palette($map-primary, 400, 700);
$malou-theme-accent: mat.m2-define-palette($map-accent, 400, 700);
$malou-theme-warn: mat.m2-define-palette($map-warn, 400, 700);

// create theme (use define-dark-theme for themes with dark backgrounds)
$malou-theme: mat.m2-define-light-theme(
    (
        color: (
            primary: $malou-theme-primary,
            accent: $malou-theme-accent,
            warn: $malou-theme-warn,
        ),
        typography: mat.m2-define-typography-config(
                $font-family: $malou-font-family,
            ),
        density: 0,
    )
);

@include mat.all-component-themes($malou-theme);

// customize malou material dialogs

.modal-at-top {
    position: absolute !important;
    top: 5vh !important;
}

.malou-overflow-visible .mat-mdc-dialog-container {
    overflow: visible;
    border-radius: 10px !important;
}

// solution for buttons overlay when not necessary
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-button-focus-overlay {
    background-color: transparent !important;
}

.post-feedback {
    margin-right: calc(100% - ((100% - 700px) / 2));
    right: 5px;

    @media screen and (max-width: 1299px) {
        margin-right: 0;
        right: inherit;
    }
}

.mat-malou-gradient {
    background: linear-gradient(45deg, #ff0000 0%, #0000ff 50%, #00ff00 100%);
}

.mat-icon {
    font-size: 16px !important;
}

.mat-mdc-option .mat-icon {
    margin-right: 0 !important; // by default, material add a margin-right of 16px
}

.mat-mdc-tab-header {
    --mat-tab-header-label-text-tracking: 0;

    border-bottom: none !important;

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: $malou-color-background-dark;
    }
}

.mat-focus-indicator::before {
    display: block !important;
}

.mat-ink-bar {
    border-radius: 20px !important;
}

div[role='tab'] {
    min-width: unset;
    padding: 0;
    margin-right: toRem(20px);
}

div[role='listbox'] {
    background-color: $malou-color-background-white !important;

    &::-webkit-scrollbar-track {
        background-color: transparent !important;
        margin: 3px 0;
    }

    scrollbar-track-color: transparent !important;
    -moz-scrollbar-track-color: transparent !important;
}

.mat-mdc-form-field-infix {
    border-top: none !important;
    padding: 0 !important;
}
