@use '_malou_variables' as *;
@use '_malou_functions' as *;
@use '_malou-typography' as *;

.mat-mdc-chip {
    --mdc-chip-elevated-container-color: #{$malou-color-white};
    --mdc-chip-label-text-font: inherit;
    --mdc-chip-label-text-size: inherit;
    --mdc-chip-label-text-weight: inherit;
}

.malou-chip-text {
    @extend .malou-text-12--medium;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.malou-chip {
    @extend .malou-chip-text;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: max-content;
    height: toRem(36px);
    padding: toRem(8px) toRem(10px);
    transition: filter 0.3s;
    border-radius: toRem(4px);

    &:hover {
        filter: contrast(90%);
    }
}

.malou-chip-icon {
    font-size: toRem(17px);
    width: toRem(17px);
    height: toRem(17px);
    padding-bottom: toRem(1px);

    &--right {
        @extend .malou-chip-icon;
        margin-left: toRem(5px);
    }

    &--left {
        @extend .malou-chip-icon;
        margin-right: toRem(5px);
    }
}

.malou-chip-number-text {
    @extend .malou-text-10;
    font-style: italic;

    margin-left: toRem(3px);
    font-weight: 100 !important;
}

// ----------------------------------------------------------------------------
// Chip colors

.malou-chip--primary {
    background: $malou-chip-background-primary !important;
    color: $malou-color-text-2 !important;
}

.malou-chip--select {
    background: $malou-chip-background-select;
    color: $malou-color-background-light;
}

.malou-chip--secondary {
    background: $malou-color-background-dark;
    color: $malou-color-text-2;
}

.malou-chip--success {
    background-color: $malou-color-state-success;
    color: $malou-color-text-white;
}

.malou-chip--success-light {
    background-color: $malou-color-state-success--light;
}

.malou-chip--error {
    background-color: $malou-color-state-error;
    color: $malou-color-text-white;
}

.malou-chip--error-light {
    background-color: rgba($malou-color-state-error, 0.1);
}

.malou-chip--red {
    background: $malou-color-bg-state-error;
    color: $malou-color-state-error;
}

.malou-chip--purple {
    background: rgba($malou-color-chart-purple--accent, 0.1);
    color: $malou-color-chart-purple--accent;
}

.malou-chip--warn {
    background-color: $malou-color-background-warning;
    color: $malou-color-state-warn;

    mat-icon {
        color: $malou-color-state-warn;
    }
    &.selected-chip {
        border: 1px solid $malou-color-state-warn;
    }
}

.malou-chip--flat {
    background-color: $malou-color-background-white;
    border: toRem(1px) solid rgba($malou-chip-text-primary, 0.25);
    color: rgba($malou-chip-text-primary, 0.5);
}

.malou-chip--dark {
    background-color: $malou-color-background-white;
    border: toRem(1px) solid $malou-color-border-primary;
    color: $malou-chip-text-primary;

    &--selected {
        background: $malou-color-background-dark !important;
        border: none;
    }
}

.malou-chip--pink-light {
    background-color: rgba($malou-color-chart-pink--light, 0.2);
    color: $malou-color-text-pink--light;
    mat-icon {
        color: $malou-color-text-pink--light;
    }
    &.selected-chip {
        border: 1px solid $malou-color-text-pink--light;
    }
}

.malou-chip--purple-light {
    background-color: rgba($malou-color-purple--light, 0.15);
    color: $malou-color-text-purple--light;
    mat-icon {
        color: $malou-color-text-purple--light;
    }
    &.selected-chip {
        border: 1px solid $malou-color-text-purple--light;
    }
}

.malou-chip--grey {
    background-color: rgba($malou-color-text-2, 0.15);
    color: $malou-color-text-2;
    mat-icon {
        color: $malou-color-text-2;
    }
    &.selected-chip {
        border: 1px solid $malou-color-text-2;
    }
}

.malou-chip--dashed {
    background-color: $malou-color-background-white;
    color: $malou-color-primary;
    mat-icon {
        color: $malou-color-primary;
    }
    &.selected-chip {
        border: 1px solid $malou-color-primary;
    }
    border: toRem(1px) dashed $malou-color-primary;
    height: toRem(34px);
}

.malou-chip--yellow {
    background-color: $malou-color-background-warning;
    color: $malou-color-state-warn;
    mat-icon {
        color: $malou-color-state-warn;
    }
    &.selected-chip {
        border: 1px solid $malou-color-state-warn;
    }
}

.malou-chip--green {
    background-color: rgba($malou-color-chart-green, 0.3);
    color: $malou-color-state-success;
    mat-icon {
        color: $malou-color-state-success;
    }
    &.selected-chip {
        border: 1px solid $malou-color-state-success;
    }
}
