@tailwind base;
@tailwind components;
@tailwind utilities;
/* You can add global styles to this file, and also import other style files */
// @import '@angular/material/prebuilt-themes/indigo-pink.css'; kept for example
@import './malou/malou.scss';
@import '@uppy/core/dist/style.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import 'cropperjs/dist/cropper.min.css';

@import './libs/ngx-drag-to-select.scss';

body,
html {
    line-height: 1.5;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    background: $malou-color-background-linear;
    min-height: 100%;
    font-family: 'Poppins', sans-serif;
    --mdc-typography-body1-font-family: 'Poppins', sans-serif;
    --mdc-typography-body2-font-family: 'Poppins', sans-serif;
    --mdc-typography-body1-font-size: 'inherit';
    --mdc-typography-body2-font-size: 'inherit';
    --mdc-typography-body1-font-weight: 'inherit';
    --mdc-typography-body2-font-weight: 'inherit';
    --mdc-typography-body1-line-height: 'inherit';
    --mdc-typography-body2-line-height: 'inherit';
    --mat-menu-item-label-text-font: 'Poppins', sans-serif;
    // all values here : https://m2.material.io/develop/web/guides/typography#typography-styles
    --mdc-typography-headline1-letter-spacing: normal;
    --mdc-typography-headline2-letter-spacing: normal;
    --mdc-typography-headline3-letter-spacing: normal;
    --mdc-typography-headline4-letter-spacing: normal;
    --mdc-typography-headline5-letter-spacing: normal;
    --mdc-typography-headline6-letter-spacing: normal;
    --mdc-typography-subtitle1-letter-spacing: normal;
    --mdc-typography-subtitle2-letter-spacing: normal;
    --mdc-typography-body1-letter-spacing: normal;
    --mdc-typography-body2-letter-spacing: normal;
    --mdc-typography-caption-letter-spacing: normal;
    --mdc-typography-button-letter-spacing: normal;
    --mdc-typography-overline-letter-spacing: normal;
    --mat-tab-header-label-text-tracking: 0;
    --mat-menu-item-label-text-tracking: 0;
    --mat-option-label-text-tracking: 0;
    --mat-select-trigger-text-tracking: 0;
    --mat-expansion-container-text-tracking: 0;
    --mat-paginator-container-text-tracking: 0;
    --mat-table-row-item-label-text-tracking: 0;
    --mat-table-row-item-label-text-line-height: normal;
}

.mat-drawer-container,
.mat-mdc-cell,
.mat-expansion-panel,
.mat-mdc-option,
html,
body {
    color: $malou-color-text-2;
    --mat-sidenav-content-text-color: #{$malou-color-text-2};
}

html {
    scrollbar-width: none; // for firefox
}

body [class^='mat-'],
body [class*=' mat-'] {
    font-family: 'Poppins';
}

body .material-icons {
    font-family: 'Material Icons';
}

body .material-icons-outlined {
    font-family: 'Material Icons Outlined';
}

body .material-icons-round {
    font-family: 'Material Icons Round';
}

.cursor {
    cursor: pointer;
}

body,
html,
p,
ul,
li {
    margin: 0;
    padding: 0;
    outline: none;
}

ul {
    list-style: none;
}

a {
    cursor: pointer;
    text-decoration: none;
}

p {
    @extend .malou-text-10--medium;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

div.mat-mdc-autocomplete-panel {
    padding: 0 !important;
}

// Show scroll bars in select-restaurants-autocompletes (people seem not to know it is scrollable)
.mat-mdc-autocomplete-panel::-webkit-scrollbar {
    -webkit-appearance: none;
}

.mat-mdc-autocomplete-panel::-webkit-scrollbar-thumb {
    box-shadow: 0 0 1px rgba($color: $malou-color-white, $alpha: 0.5);
    -webkit-box-shadow: 0 0 1px rgba($color: $malou-color-white, $alpha: 0.5);
}

.ace_editor.ace-jsoneditor {
    min-height: 715px;
}

img.ng-lazyloaded {
    animation: fadein 0.9s;
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.selected-media,
.selected-folder {
    background-color: $malou-color-background-dark;
}

.cdk-overlay-container {
    top: 0 !important;
}

.cdk-overlay-container .cdk-overlay-pane .malou-select-panel {
    box-shadow: 0px 4px 14px rgba(106, 82, 253, 0.3) !important;
    border-radius: 10px !important;
    color: $malou-color-text-2 !important;
    background-color: $malou-color-white;

    .mat-mdc-option span {
        font-size: 12px !important;
    }
}

.mat-mdc-autocomplete-panel:not([class*='mat-elevation-z']) {
    @extend .malou-box-shadow;
}

// we have to duplicate the styles here because you can't use "@ tailwind utilities" in any files
// you can use a preprocessor but it seems to slow down the build process : https://tailwindcss.com/docs/using-with-preprocessors#using-sass-less-or-stylus
@layer utilities {
    // Font Weight

    @each $weight in map-keys($font-weights) {
        .malou-text-weight-#{$weight} {
            font-weight: map-get($font-weights, $weight);
        }
    }

    // Text Transform

    .malou-text-transform-uppercase {
        text-transform: uppercase;
    }

    .malou-text-transform-lowercase {
        text-transform: lowercase;
    }

    // Line Height

    .malou-text-height-18 {
        line-height: 18px;
    }

    // Font Size

    @each $size in $font-sizes {
        .malou-text-#{$size} {
            font-size: #{$size}px;

            @each $weight in map-keys($font-weights) {
                &--#{$weight} {
                    font-size: #{$size}px;
                    font-weight: map-get($font-weights, $weight);
                }
            }
        }
    }

    // Other

    .malou-text-heading {
        font-size: 44px;
        font-weight: 600;
        color: $malou-color-text-1;
    }

    .malou-text-title {
        font-size: 32px;
        font-weight: 700;
    }

    .malou-text-section-title {
        font-size: 18px;
        font-weight: 700;
    }

    .malou-text__card-title {
        font-size: 18px !important;
        font-weight: $malou-font-weight-extrabold;
    }

    .malou-text__card-subtitle {
        font-size: 10px !important;
        font-style: italic;
        color: $malou-color-text-2;
    }
}

.mention-dropdown {
    box-shadow: $malou-shadow-1 !important;
    border: none !important;
    border-radius: 10px !important;
    height: fit-content !important;
    max-height: 200px !important;
    .mention-active {
        .dropdown-item.mention-item {
            background-color: $malou-color-background-dark !important;
        }
    }
}

.mention-item {
    padding: 1em 1.5em !important;
    color: $malou-color-text-1 !important;
    font-size: toRem(10px) !important;
    font-weight: $malou-font-weight-semibold !important;
}

.cross-tag {
    position: absolute;
    top: -20px;
    right: 0px;
    background-color: $malou-color-white;
    width: 14px;
    height: 14px;
    text-align: center;
    border-radius: 50%;
    font-size: 10px;
    line-height: 14px;
    color: $malou-color-primary;
    cursor: pointer;
}

body {
    .notifications-modal {
        &-backdrop {
            background-color: transparent !important;
        }
        &-panel {
            .mat-mdc-dialog-surface.mdc-dialog__surface {
                box-shadow: 0px 4px 14px rgba(106, 82, 253, 0.3) !important;
                border-radius: 10px !important;
            }
        }
    }
}

#front-chat-iframe {
    z-index: 1000 !important;
}
